.delete-button {
    border-radius: 50px;
    padding: 5px;
    background-color: rgb(201, 19, 95);
    cursor: pointer;
    // margin-left: 10px;
}

.add-button {
    border-radius: 50px;
    padding: 5px;
    background-color: rgb(152, 201, 19);
    cursor: pointer;
    // margin-right: 10px;
}

.edit-button {
    border-radius: 50px;
    padding: 5px;
    background-color: rebeccapurple;
    cursor: pointer;
}

.entity {
    margin: auto;
    // border: 2px solid rgb(21, 64, 125);
    // margin: 10px;
    min-width: 230px;
    // border-radius: 2px;
    // box-shadow: 0 0 0.5px 2px #48abe0;
    // border-radius: 20px;
    // background: rgb(61, 61, 61) !important;
    color: white;

    .entity-dragbar {
        // background-color: rgb(0, 0, 0);
        cursor: pointer;
        height: 20px !important;
        width: 100% !important;
        display: flex;
    }

    .header {
        padding: 10px;
        font-weight: bold;
        color: white;
        // background: rgb(57, 57, 57) !important;
        display: flex;
        justify-content: space-between !important;
        // height: 30px;
        align-items: center !important;
        border: 1px solid rgb(93, 92, 92);
        .header-side {
            //    flex: 1;
            width: 120px;
            margin-left: 15px;
        }

    }

    .data-types {
        border-collapse: collapse;
        width: 100%;

        td,
        th {
            border: 1px solid rgb(93, 92, 92);

        }

        td {
            padding-left: 10px;
            padding: 5px;
            // display: flex;
            // justify-content: center;
        }
    }

    .block {
        // padding:2px 10px;
        // display: flex;
        // justify-content: start;
        // background: white;

        // border-bottom: 2px solid rgb(21, 64, 125);
        .left {
            // overflow: hidden;
            // width: 70%;
            // border-right: 2px solid rgb(21, 64, 125);
        }

        .right {
            // overflow: hidden;
            // width: 30%;
            // padding-left: 5px;
        }
    }
}



.flow-scene {
    display: flex;
    width: 100%;
    height: 100vh;
    background: rebeccapurple;

    .editor {
        background: rgb(29, 29, 28);
        width: 100%;
        // zoom: 0.4;
        .block-container{
            width: 100px;
            margin-top: 20px;
            position: relative;
                            
            .block-dot {
                position: absolute;
                display: flex;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                cursor: pointer;
            }
            .top-dot {
                left: 50%;
                top: -10px;
                transform: translate(-50%);
                background-color: rgb(255, 255, 255);
            }
            header{
                user-select: none;
                background: red;
                padding: 6px 0;
            }
            .block {
                padding: 10px;
                position: relative;
                background: black;
                .bottom-success-dot {
                    left: 30%;
                    bottom: -10px;
                    transform: translate(-50%);
                    background-color: rgb(31, 255, 2);
                }
                .bottom-fail-dot {
                    left: 70%;
                    bottom: -10px;
                    transform: translate(-50%);
                    background-color: rgb(255, 0, 0);
                }
            }
        }
       
    }

    .drop-box {
        width: 250px;
        background: rgb(0, 0, 0);
        padding: 10px;

        .heading {
            background-color: black;
            font-weight: bold;
        }

        .type {
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgb(73, 72, 73);
            width: 100%;
            height: 40px;
            user-select: none;
            cursor: pointer;

        }
    }
}




.react_fast_diagram_Background{
    background-color: rgb(18, 18, 18) !important;
}
.react_fast_diagram_NodeLabel{
    color: black !important;
}


.rst__rowTitle{
    // color: black !important;
}


.rst__lineHalfHorizontalRight::before, 
.rst__lineFullVertical::after,
 .rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after,
.rst__lineChildren::after {
  background: rgb(255, 255, 255) !important;
  font-size: 10px !important;
  border:0.5px rgb(0, 55, 255) solid;
}


.cant-click{
    background: rgb(255, 0, 132) !important;
}
.rst__rowLabel,.rst__rowTitle{

    width: 100%;
}
.flow-node-space{
    display: flex !important;
    justify-content: space-between !important;
    align-items: center;
    .left{
        width: 100%;
        flex: auto;
    }
    .right{
       width: 70px;

    }
    
}
.node .rst__moveHandle{
    background: rgb(69, 69, 69);
}
.node .rst__rowContents{
    background-color: rgb(27, 27, 27);
}

.flow-script{
    // border:5px rgb(0, 255, 72) solid;
    background: rebeccapurple;
    display: flex;
    // border:4px rgb(0, 0, 0) solid;
}

.rst__moveHandle
 {
	background-image : none;
	padding-top : 6px;
	text-align: center;
}
.flow-fail{
    border:2px red solid;
}

.flow-success{
    border:2px rgb(0, 255, 72) solid;
}




.rst__tree{
    // transform: scale(0.8)
}